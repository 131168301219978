import {useState} from 'react'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../../helpers/for-changing-requests-state/create-request-state'
import {RequestStateInterface} from '../../../../../types/state'
import unbind from '../../../../../requests/dms/unbind'
import Binding from '../../../../../types/models/binding'

const getUnbindInitialState = createInitialState<string>()

export default function useUnbindRequest(
    binding: Binding,
    onSuccessUnbinding: () => void
) {
    const [unbindState, setUnbindState] = useState<
        RequestStateInterface<string>
    >(getUnbindInitialState)

    const runUnbind = async () => {
        setUnbindState(createStartedState())

        try {
            const result = await unbind(binding.clientId, binding.bindingId)
            onSuccessUnbinding()

            setUnbindState(createSuccessState(result))
            return result
        } catch (e) {
            setUnbindState(createErrorState([e.message]))
            return null
        }
    }

    return {runUnbind, unbindState}
}
