import FieldWithMask from '../../kit/forms-common/field/with-mask'
import Button from '../../kit/button'
import {ROUTES} from '../../../navigation/routes'
import * as React from 'react'
import {useFormik} from 'formik'
import './index.less'
import LinkBelowTheForm from '../../kit/forms-common/link-below-the-form'
import ErrorParagraph from '../../kit/forms-common/error-paragraph'
import AnswerParagraph from '../../kit/forms-common/success-paragraph'
import validateCallback from './validate'
import onSubmitCallback from './on-submit'
import {useLocation} from 'react-router'
import {useEffect} from 'react'

export const PasswordResetForm = (props: {}) => {
    const {search} = useLocation()
    const searchParams = new URLSearchParams(search)
    const phone = searchParams.get('phone')
    console.log(phone)
    const [errors, setErrors] = React.useState<string>(''),
        [answer, setAnswer] = React.useState<string>(''),
        formik = useFormik({
            initialValues: {
                phone: '+' + phone?.slice(1),
            },
            validate: validateCallback,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: values => onSubmitCallback(values, setAnswer, setErrors),
        })

    useEffect(() => {
        return () => {
            formik.resetForm()
        }
    }, [])

    return (
        <form className={'pwd-restore-form'} onSubmit={formik.handleSubmit}>
            <FieldWithMask
                label={'Телефон'}
                fieldName={'phone'}
                value={formik.values.phone}
                onChange={formik.handleChange}
                errors={formik.errors.phone}
                mask={'+7 (999) 999-99-99'}
                type={'tel'}
            />
            <div className={'pwd-restore-form__submit-btn'}>
                <Button buttonType={'primary'}>Сбросить</Button>
            </div>
            <ErrorParagraph errors={errors} />
            <AnswerParagraph answer={answer} />
            <LinkBelowTheForm to={ROUTES.AUTH}>
                Вернуться на страницу входа
            </LinkBelowTheForm>
        </form>
    )
}

export default React.memo(PasswordResetForm)
