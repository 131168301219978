import {Modal} from 'antd'
import {useEffect} from 'react'
import {useHistory} from 'react-router'

export default function useBindingResultModals() {
    const history = useHistory()

    useEffect(() => {
        const success = new URLSearchParams(window.location.search).get(
            'success'
        )

        const onOk = () => {
            const queryParams = ''
            history.replace({
                search: queryParams,
            })
        }

        if (success === 'true') {
            Modal.success({title: 'Карта успешно привязана', onOk})
        } else if (success === 'false') {
            Modal.error({
                title: 'Ошибка привязки карты',
                content:
                    'Пожалуйста, обратитесь в службу технической поддержки',
                onOk,
            })
        }
    }, [])
}
