import {DOMEN} from '../../constants/urls'
import {GetBindingsResponseType} from '../../types/responses/get-bindings'

const getBindings = (clientId: string): Promise<GetBindingsResponseType> =>
    fetch(`${DOMEN}/payment/auto/client/${clientId}/bindings`, {
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
    }).then(resp => resp.json())

export default getBindings
