import {useState} from 'react'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../../helpers/for-changing-requests-state/create-request-state'
import {RequestStateInterface} from '../../../../../types/state'
import createBinding from '../../../../../requests/dms/create-binding'
import CreateBindingRequestInterface from '../../../../../types/requests/create-binding'

const createBindingInitialState = createInitialState<string>()

export default function useCreateBindingRequest() {
    const [createBindingState, setCreateBindingState] = useState<
        RequestStateInterface<string>
    >(createBindingInitialState)

    const getCreateBindingPayload = (): CreateBindingRequestInterface => {
        return {
            successUrl: window.location + '?success=true',
            failUrl: window.location + '?success=false',
        }
    }

    const runCreateBinding = async (clientId: string) => {
        setCreateBindingState(createStartedState())

        try {
            const result = await createBinding(
                clientId,
                getCreateBindingPayload()
            )

            setCreateBindingState(createSuccessState(result))
            return result
        } catch (e) {
            setCreateBindingState(createErrorState([e.message]))
            return null
        }
    }

    return {runCreateBinding, createBindingState}
}
