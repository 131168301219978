import * as React from 'react'
import {FC, useEffect} from 'react'
import {Header} from '../../../layout/header'
import MarginWrapper from '../../../layout/margin-wrapper'
import PageHeader from '../components/page-header'
import {useSelector} from 'react-redux'
import {RootStateType} from '../../../../store/reducers'
import Button from '../../../kit/button'
import Space from 'antd/lib/space'
import 'antd/lib/space/style'
import BindingCard from './binding-card'
import RingLoaderCustomized from '../../../kit/ring-loader-customized'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import useBindingResultModals from './hooks/use-binding-result-modals'
import useGetClientsRequest from './hooks/use-get-clients-request'
import useCreateClientRequest from './hooks/use-create-client-request'
import useCreateBindingRequest from './hooks/use-create-binding-request'
import useGetBindingsRequest from './hooks/use-get-bindings-request'

interface BankCardProps {}

const BankCard: FC<BankCardProps> = () => {
    const info = useSelector((state: RootStateType) => state.info.data)
    const getInfoState = useSelector((state: RootStateType) => state.info)

    const {runGetClients, getClientState} = useGetClientsRequest()
    const {runCreateClient, createClientState} = useCreateClientRequest()
    const {runCreateBinding, createBindingState} = useCreateBindingRequest()
    const {runGetBindings, getBindingsState} = useGetBindingsRequest()

    useBindingResultModals()

    useEffect(() => {
        if (!info?.phone) return
        runGetClients(info.phone).then(() => {})
    }, [info?.phone])

    useEffect(() => {
        if (
            getClientState.requestStatus === 'success' &&
            getClientState.data &&
            getClientState.data?.length !== 0
        ) {
            runGetBindings(getClientState.data[0].clientId)
        }
    }, [getClientState])

    const onAddCard = async () => {
        let clientId = ''

        if (
            getClientState.requestStatus === 'success' &&
            getClientState.data &&
            getClientState.data.length > 0
        ) {
            clientId = getClientState.data[0].clientId
        } else {
            const client = await runCreateClient()

            if (client) {
                clientId = client.clientId
            }
        }

        const bindingUrl = await runCreateBinding(clientId)

        if (bindingUrl) {
            window.open(bindingUrl)
        }
    }

    const bindings = (getBindingsState.data || []).filter(
        item => item.status !== 'cancelled'
    )
    const clientHasBinding = bindings.length > 0

    const clientsNotFound =
        getClientState.requestStatus === 'success' &&
        getClientState.data?.length === 0
    const clientHaveNotBindings =
        getBindingsState.requestStatus === 'success' && !clientHasBinding
    const showAddBindingButton = clientsNotFound || clientHaveNotBindings

    return (
        <>
            <Header />
            <MarginWrapper className={'main'}>
                <PageHeader />
                {showAddBindingButton && (
                    <Button style={{marginTop: 14}} onClick={onAddCard}>
                        {createClientState.requestStatus === 'started' ||
                        createBindingState.requestStatus === 'started' ? (
                            <LoadingOutlined />
                        ) : null}{' '}
                        Добавить карту
                    </Button>
                )}

                {getInfoState.requestStatus === 'started' ||
                getClientState.requestStatus === 'started' ||
                getBindingsState.requestStatus === 'started' ? (
                    <RingLoaderCustomized />
                ) : (
                    <Space style={{marginTop: 24, display: 'block'}}>
                        {bindings.map(item => {
                            return (
                                <BindingCard
                                    binding={item}
                                    key={item.bindingId}
                                    onSuccessUnbinding={() => {
                                        runGetBindings(item.clientId)
                                    }}
                                />
                            )
                        })}
                    </Space>
                )}
            </MarginWrapper>
        </>
    )
}

export default BankCard
