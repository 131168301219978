export enum ROUTES {
    AUTH = '/',
    PERSONAL_DATA = '/personal-data',
    REGISTRATION = '/registration',
    PASSWORD_RESTORE = '/password-restore',

    PERSONAL_DATA_FORM = '/personal-data/:popupFormId',
    POLICIES = '/policies',
    POLICIES_ARCHIVE = '/policies/archive',
    APPEALS = '/appeals',
    APPEAL = '/appeals/:id',

    CREATE_APPEAL = '/appeals/create',
    INSURANCE_CASE = '/insurance-case',
    INSURANCE_CASE_ACCIDENT = '/insurance-case/accident',
    INSURANCE_CASE_TRAVEL = '/insurance-case/travel',
    INSURANCE_CASE_HEALTH = '/insurance-case/health',
    INSURANCE_CASE_PROPERTY = '/insurance-case/property',
    INSURANCE_CASE_AUTO = '/insurance-case/auto',

    SHOP = '/shop',
    SHOP_TRAVEL_STANDARD = '/shop/travel/standard',
    SHOP_OSAGO_STANDARD = '/shop/osago/standard',
    SHOP_OSAGO_STANDARD_ISN = '/shop/osago/standard/:isn',
    SHOP_PROPERTY_STANDARD = '/shop/property/standard',
    SHOP_KASKO_STANDARD = '/shop/kasko/standard',
    SHOP_DMS_STANDARD = '/shop/dms/standard',
    SHOP_NS_SPORT = '/shop/ns/sport',
    SHOP_NS_COVID = '/shop/ns/covid',
    SHOP_NS_ANTIMITE = '/shop/ns/antimite',

    BONUSES = '/bonuses',

    CLIENT_AGENT = '/client-agent',
    DMS = '/dms',
    DMS_GUARANTEE_MAILS = '/dms/guarantee-mails',
    DMS_CONTRACT = `/dms/:isn`,
    DMS_INVOICES = `/dms/invoices`,
    DMS_BANK_CARD = `/dms/bank-card`,

    NOT_FOUND = '*',
}
