import {useState} from 'react'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../../helpers/for-changing-requests-state/create-request-state'
import Binding from '../../../../../types/models/binding'
import {RequestStateInterface} from '../../../../../types/state'
import getBindings from '../../../../../requests/dms/get-bindings'

const getBindingsInitialState = createInitialState<Binding[]>()

export default function useGetBindingsRequest() {
    const [getBindingsState, setGetBindingsState] = useState<
        RequestStateInterface<Binding[]>
    >(getBindingsInitialState)

    const runGetBindings = async (clientId: string) => {
        setGetBindingsState(createStartedState())

        try {
            const result = await getBindings(clientId)

            setGetBindingsState(createSuccessState(result))
        } catch (e) {
            setGetBindingsState(createErrorState([e.message]))
        }
    }

    return {runGetBindings, getBindingsState}
}
