import {FormikErrors} from 'formik'
import {PasswordRestoreFormInterface} from '../../../types/forms'

export default (values: PasswordRestoreFormInterface) => {
    const errors: FormikErrors<PasswordRestoreFormInterface> = {}
    if (!/\+7\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}/.test(values.phone)) {
        errors.phone = 'Введите телефон'
    }
    return errors
}
