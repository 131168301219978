import {useState} from 'react'
import {RequestStateInterface} from '../../../../../types/state'
import Client from '../../../../../types/models/client'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../../helpers/for-changing-requests-state/create-request-state'
import getClients from '../../../../../requests/dms/get-clients'

const getClientsInitialState = createInitialState<Client[]>()

export default function useGetClientsRequest() {
    const [getClientState, setGetClientState] = useState<
        RequestStateInterface<Client[]>
    >(getClientsInitialState)

    const runGetClients = async (phone: string) => {
        setGetClientState(createStartedState())

        try {
            const result = await getClients(phone)

            setGetClientState(createSuccessState(result))
        } catch (e) {
            console.error(e)
            setGetClientState(createErrorState([e.message]))
        }
    }

    return {runGetClients, getClientState}
}
