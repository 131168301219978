import {DOMEN} from '../../constants/urls'
import CreateBindingRequestInterface from '../../types/requests/create-binding'

const createBinding = (
    autoPaymentClientId: string,
    payload: CreateBindingRequestInterface
): Promise<string> =>
    fetch(`${DOMEN}/payment/auto/binding/${autoPaymentClientId}/bind`, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload),
    }).then(resp => resp.text())

export default createBinding
