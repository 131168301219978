import {URL_CREATE_CLIENT} from '../../constants/urls'
import Client from '../../types/models/client'
import CreateClient from '../../types/requests/create-client'

export default async function createClient(
    createClientArg: CreateClient
): Promise<Client> {
    return fetch(URL_CREATE_CLIENT, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(createClientArg),
    }).then(resp => resp.json())
}
