export const DOMEN = process.env.API_URL
    ? process.env.API_URL
    : process.env.NODE_ENV === 'development'
    ? process.env.LOCAL_API_HOST
    : 'https://api.euro-ins.ru'

/*
    Аккаунт
 */
export const URL_LOGIN = `${DOMEN}/account/login`
export const URL_LOGOUT = `${DOMEN}/account/logout`
export const URL_INFO = `${DOMEN}/account/info`
export const URL_AGREEMENTS = `${DOMEN}/account/agreements`
export const URL_USER_AGREEMENTS = `${DOMEN}/account/user/agreements`
export const URL_APPEALS = `${DOMEN}/account/appeals`
export const URL_ATTACHMENT = `${DOMEN}/account/attachment`
export const URL_ADD_APPEAL = `${DOMEN}/account/appeals/add`
export const URL_REGISTRATION = `${DOMEN}/account/new`
export const URL_SET_USER_INFO = `${DOMEN}/account/info`
export const URL_FILE = `${DOMEN}/account/file`
export const URL_READ_BECOME_AGENT_STATUS = `${DOMEN}/account/agent/status`
export const URL_CREATE_BECOME_AGENT = `${DOMEN}/account/agent/become`
export const URL_COOKIE_STATUS = `${DOMEN}/account/authorization_status`
export const URL_PASSWORD_RESET = `${DOMEN}/account/recover`
export const URL_INVITE = `${DOMEN}/account/agent/invite`
export const URL_INVITATIONS = `${DOMEN}/account/agent/invitations`
export const URL_SALES = `${DOMEN}/account/agent/sales`
export const URL_CASH_BACK = `${DOMEN}/account/agent/cashback`
export const URL_DMS_CONTRACTS = `${DOMEN}/account/dms/contracts`
export const URL_DMS_CONTRACT_SEARCH_AND_ADD = `${DOMEN}/account/dms/contracts/insured`
export const URL_CHECK_DMS_CONTRACT = `${DOMEN}/account/dms/contracts/insured/check`
export const URL_SAVE_DMS_CONTRACT = `${DOMEN}/account/dms/contracts`
export const URL_DMS_GET_GUARANTEE = `${DOMEN}/account/dms/guarantee`
export const URL_GET_LPU_INFO = `${DOMEN}/account/dms/lpu/info`
export const URL_GET_INVOICES = `${DOMEN}/account/dms/contracts/insured/invoice`
export const URL_GET_PAYMENT_CLIENTS = `${DOMEN}/payment/auto/clients`
export const URL_CREATE_CLIENT = `${DOMEN}/payment/auto/client`

/*
    Бонусы
 */
export const URL_BONUSES_GET_AMOUNT = `${DOMEN}/bonus/get_client_bonus`
export const URL_BONUSES_ACTIVATE_WELCOME_BONUSES = `${DOMEN}/bonus/activate`
export const URL_BONUSES_IS_WELCOME_BONUSES_ACTIVATED = `${DOMEN}/bonus/is_activated`
export const URL_BONUSES_HISTORY = `${DOMEN}/bonus/history`

/*
    Киас
 */
export const URL_GET_CITIES_DICTIONARY = `${DOMEN}/kias_dictionary/locality`
export const URL_GET_CITY_BY_ISN = `${DOMEN}/kias_dictionary/locality/:_cityIsn`
