import * as React from 'react'
import {FC, useEffect, useReducer, useState} from 'react'
import {Header} from '../../../layout/header'
import MarginWrapper from '../../../layout/margin-wrapper'
import PageHeader from '../components/page-header'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../helpers/for-changing-requests-state/create-request-state'
import Invoice from '../../../../types/models/invoice'
import {RequestStateInterface} from '../../../../types/state'
import getInvoices from '../../../../requests/dms/get-invoices'
import {Table} from 'antd'
import {RootStateType} from '../../../../store/reducers'
import {useSelector} from 'react-redux'

const getInitialState = createInitialState<Invoice>()

interface InvoicesProps {}

const Invoices: FC<InvoicesProps> = () => {
    const info = useSelector((state: RootStateType) => state.info.data)
    console.log(info)
    const [getInvoicesState, setGetInvoicesState] = useState<
        RequestStateInterface<Invoice>
    >(getInitialState)
    const isLoading = getInvoicesState.requestStatus === 'started'
    const invoices = getInvoicesState.data ? [getInvoicesState.data] : []

    const runGetInvoices = async (insuredIsn: string) => {
        setGetInvoicesState(createStartedState())
        try {
            const result = await getInvoices(+insuredIsn)
            if (result.success)
                setGetInvoicesState(createSuccessState(result.data))
            else setGetInvoicesState(createErrorState(result.errors))
        } catch (e) {
            setGetInvoicesState(createErrorState([e.message]))
        }
    }

    useEffect(() => {
        if (!info?.isn) return
        runGetInvoices(info.isn)
    }, [info?.isn])

    return (
        <>
            <Header />
            <MarginWrapper className={'main'}>
                <PageHeader />

                <Table
                    dataSource={invoices}
                    loading={isLoading}
                    pagination={false}
                    rowKey={record => record.id}
                >
                    <Table.Column title={'Номер'} dataIndex={'id'} />
                    <Table.Column title={'Дата'} dataIndex={'docDate'} />
                    <Table.Column title={'Итого (руб.)'} dataIndex={'amount'} />
                    <Table.Column title={'Статус'} dataIndex={'statusName'} />
                </Table>
            </MarginWrapper>
        </>
    )
}

export default Invoices
