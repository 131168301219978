import * as React from 'react'
import {FC, useMemo} from 'react'
import './index.less'
import Binding from '../../../../../types/models/binding'
import Card from 'antd/lib/card'
import 'antd/lib/card/style'
import Button from 'antd/lib/button'
import 'antd/lib/button/style'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import EditOutlined from '@ant-design/icons/EditOutlined'
import notification from 'antd/lib/notification'
import 'antd/lib/notification/style'
import Space from 'antd/lib/space'
import 'antd/lib/space/style'
import useUnbindRequest from '../hooks/use-unbind-request'
import useCreateBindingRequest from '../hooks/use-create-binding-request'

interface BindingCardProps {
    binding: Binding
    onSuccessUnbinding: () => void
}

const BindingCard: FC<BindingCardProps> = ({binding, onSuccessUnbinding}) => {
    const {runUnbind, unbindState} = useUnbindRequest(
        binding,
        onSuccessUnbinding
    )
    const {runCreateBinding, createBindingState} = useCreateBindingRequest()

    const cardDate = useMemo(() => {
        const arr = binding.card.expiration.split('')
        arr.splice(4, 0, '/')
        return arr.join('')
    }, [])

    const onChangeCard = async () => {
        notification.info({message: 'Отвязка предыдущей карты...', top: 118})
        await runUnbind()
        notification.info({message: 'Привязка новой карты', top: 118})
        const bindingUrl = await runCreateBinding(binding.clientId)

        if (bindingUrl) window.open(bindingUrl)
    }

    return (
        <Card className={'binding-card'}>
            <p>
                Номер: <b>{binding.card.number}</b>
            </p>
            <p>
                Истекает:<b> {cardDate}</b>
            </p>
            <p>
                Привязана:{' '}
                <b>
                    {binding.created
                        .slice(0, 10)
                        .split('-')
                        .reverse()
                        .join('.')}{' '}
                    {binding.created.slice(12)}
                </b>
            </p>
            <Space>
                <Button
                    icon={<EditOutlined />}
                    shape={'round'}
                    title={'Изменить привязку'}
                    onClick={onChangeCard}
                >
                    Изменить
                </Button>
                <Button
                    icon={<DeleteOutlined />}
                    shape={'round'}
                    danger
                    title={'Удалить привязку'}
                    onClick={runUnbind}
                >
                    Удалить
                </Button>
            </Space>
        </Card>
    )
}

export default BindingCard
