import {useState} from 'react'
import {
    createErrorState,
    createInitialState,
    createStartedState,
    createSuccessState,
} from '../../../../../helpers/for-changing-requests-state/create-request-state'
import Client from '../../../../../types/models/client'
import {RequestStateInterface} from '../../../../../types/state'
import createClient from '../../../../../requests/dms/create-client'
import CreateClient from '../../../../../types/requests/create-client'
import {RootStateType} from '../../../../../store/reducers'
import {useSelector} from 'react-redux'

const createClientInitialState = createInitialState<Client>()

export default function useCreateClientRequest() {
    const info = useSelector((state: RootStateType) => state.info.data)

    const [createClientState, setCreateClientState] = useState<
        RequestStateInterface<Client>
    >(createClientInitialState)

    const getCreateClientArg = (): CreateClient => {
        return {
            phone: info?.phone || '', // "+79123456789"
            lastName: info?.lastName || '', // "Иванов"
            firstName: info?.firstName || '', // "Иван"
            middleName: info?.middleName || '', //  "Иванович"
            email: info?.email || '', // "foo@bar.com"
        }
    }

    const runCreateClient = async () => {
        setCreateClientState(createStartedState())

        try {
            const createClientArg = getCreateClientArg()
            const result = await createClient(createClientArg)

            setCreateClientState(createSuccessState(result))
            return result
        } catch (e) {
            setCreateClientState(createErrorState([e.message]))
            return null
        }
    }

    return {runCreateClient, createClientState}
}
