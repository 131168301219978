import React, {FunctionComponent} from 'react'
import {Switch, Route} from 'react-router-dom'
import {ROUTES} from '../../../navigation/routes'
import {Auth} from '../../pages/auth'
import {Registration} from '../../pages/register'
import PasswordRestore from '../../pages/password-restore'
import './index.less'
import '../../common-styles/index.less'
import PrivateSwitch from './private-switch'
import ConfigProvider from 'antd/lib/config-provider'
import ruRU from 'antd/es/locale/ru_RU'

//prettier-ignore
export const App: FunctionComponent = () => {
    return (
        <ConfigProvider locale={ruRU}>
            <div className={'app'}>
                <Switch>
                    <Route exact path={ROUTES.AUTH} component={Auth}/>
                    <Route exact path={ROUTES.PASSWORD_RESTORE} component={PasswordRestore}/>
                    <Route exact path={ROUTES.REGISTRATION} component={Registration} />
                    <PrivateSwitch />
                </Switch>
            </div>
        </ConfigProvider>
    )
}
