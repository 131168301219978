import {PasswordRestoreFormInterface} from '../../../types/forms'
import resetPasswordRequest from '../../../requests/reset-password'

interface SetAnswerInterface {
    (answer: string): void
}

interface SetErrorsInterface {
    (errors: string): void
}

export default (
    {phone}: PasswordRestoreFormInterface,
    setAnswer: SetAnswerInterface,
    setErrors: SetErrorsInterface
) => {
    const formattedPhone = phone.replace(/\D/g, '').slice(1) // убираем все НЕ-цифры + семерку
    resetPasswordRequest(formattedPhone)
        .then(ans => setAnswer(ans.data))
        .catch(setErrors)
}
