import {URL_GET_INVOICES} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'
import {GetInvoicesResponceType} from '../../types/responses/get-invoices'

const getInvoices = (insuredIsn: number): Promise<GetInvoicesResponceType> =>
    fetch(URL_GET_INVOICES, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            insuredIsn,
        }),
    }).then(handleResponse)
export default getInvoices
