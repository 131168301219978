import {URL_GET_PAYMENT_CLIENTS} from '../../constants/urls'
import Client from '../../types/models/client'

const getClients = (phone: string): Promise<Client[]> =>
    fetch(URL_GET_PAYMENT_CLIENTS, {
        method: 'POST',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({phone}),
    }).then(resp => resp.json())

export default getClients
