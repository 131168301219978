import * as React from 'react'
import PasswordResetForm from '../../forms/password-reset-form'
import EnterPagesBg from '../../layout/bg/enter-pages'

export const PasswordRestore = (props: {}) => {
    return (
        <EnterPagesBg>
            <h1 className='auth-heading'>Сброс пароля</h1>
            <PasswordResetForm />
        </EnterPagesBg>
    )
}

export default React.memo(PasswordRestore)
