import {DOMEN} from '../../constants/urls'

const unbind = (clientId: string, bindingId: string): Promise<string> =>
    fetch(
        `${DOMEN}/payment/auto/client/${clientId}/binding/${bindingId}/unbind`,
        {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
        }
    ).then(resp => resp.text())

export default unbind
