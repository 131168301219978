import {URL_PASSWORD_RESET} from '../../constants/urls'
import {handleResponse} from '../../helpers/for-requests'

const resetPasswordRequest = (phone: string) => {
    return fetch(URL_PASSWORD_RESET, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({phone}),
    }).then(handleResponse)
}

export default resetPasswordRequest
