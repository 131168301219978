import * as React from 'react'
import Paragraph from '../../paragraph'

const AnswerParagraph = ({answer}: {answer: string | undefined}) => {
    return answer ? (
        <Paragraph overrideStyles={{color: 'green', textAlign: 'center'}}>
            {answer}
        </Paragraph>
    ) : null
}

export default React.memo(AnswerParagraph)
